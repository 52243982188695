






















































@import '@design';
.theme--light.cta-box {
  height: calc(100% - 20px); // this is to fake fill-height minus border radius
  border: solid 10px $color-bluegiant-red;
  border-radius: 0;
}
.cta--text {
  display: inline-block;
}
